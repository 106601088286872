import { find } from "ramda";
import TextBlock from "./text";
import TextAreaBlock from "./textarea";
import SliderBlock from "./slider";
import PostSlider from "./post-slider";
import Audience from "./audience";
import Placements from "./placements";
import Buttons from "./buttons";
import SwitchOption from "./options/switch";
import SelectOption from "./options/select";
import TextOption from "./options/text";
import TextAreaOption from "./options/textarea";
import { Divider } from "antd";

export default function Blocks({
  data = null,
  widgets = [],
  onChange,
  onOptionChange,
}) {
  if (!data || !widgets) return null;

  const widget = find((w) => w.type === data.type, widgets);
  const inputs = widget?.default_values?.inputs || [];
  const options = widget?.default_values?.options
    ? Object.keys(widget.default_values.options).map((key) => {
        return { key, data: widget.default_values.options[key] };
      })
    : [];

  return (
    <div className="grid grid-cols-12 gap-4 my-3">
      {inputs.map((input, key) => {
        switch (input.type) {
          case "text":
            return (
              <TextBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "textarea":
            return (
              <TextAreaBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "audience":
            return (
              <Audience
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "placements":
            return (
              <Placements
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "buttons":
            return (
              <Buttons
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "post-slider":
            return (
              <PostSlider
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "articles:slider":
            return (
              <SliderBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );
          default:
            return null;
        }
      })}

      <Divider className="w-full col-span-12 my-2" />

      {options.map((o) => {
        switch (o.data.type) {
          case "select":
            return (
              <SelectOption option={o} onChange={onOptionChange} data={data} />
            );

          case "switch":
            return (
              <SwitchOption option={o} onChange={onOptionChange} data={data} />
            );

          case "text":
            return (
              <TextOption option={o} onChange={onOptionChange} data={data} />
            );

          case "textarea":
            return (
              <TextAreaOption
                option={o}
                onChange={onOptionChange}
                data={data}
              />
            );

          default:
            break;
        }
      })}
    </div>
  );
}
