import { useEffect, useState } from "react";
import { message, Select, Spin, Form } from "antd";

export default function ArticleSearch(params) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const defaultValue =
      params?.form?.getFieldValue(["meta", "perferred_tag_id"]) || null;

    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, []);

  const fetchData = () => {
    const tags = params.form.getFieldValue("tags") || [];

    setOptions(
      tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }))
    );
  };

  return (
    <Form.Item
      {...params}
      name={["meta", "perferred_tag_id"]}
      className="w-full"
      label="Предпочтительный тег (для рекомендаций)"
      tooltip="По этому тегу будут показываться рекомендации после материала"
    >
      <Select
        // loading={loading}
        // disabled={loading}
        onChange={(_, value) => {
          setSelected(value?.data);
        }}
        showSearch
        className="w-full"
        allowClear
        placeholder="Выберите тег"
        options={options}
      />
    </Form.Item>
  );
}
