import { Input, Button, Drawer, Select, Space, Empty } from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  UpOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  InstagramOutlined,
  TikTokOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

export default function Widget({ input, onChange, key, data }) {
  const [options, setOptions] = useState(data?.data?.[input.key] || []);
  const [option, setOption] = useState(null);

  useEffect(() => {
    onChange(input.key, options);
  }, [options]);

  const onOptionAdd = () => {
    setOptions([...options, { source: "instagram" }]);
    setOption(options.length);
  };

  const onOptionPositionChange = (index, position) => {
    const _options = [...options];
    const item = _options[index];
    _options.splice(index, 1);
    _options.splice(index + position, 0, item);
    setOptions(_options);
  };

  const onOptionDelete = (index) => {
    const _options = [...options];
    _options.splice(index, 1);
    setOptions(_options);
  };

  const getDrawerContent = () => {
    if (option === null) {
      return null;
    }

    const item = options[option];

    return (
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Источник
          </span>
          <Select
            className="w-full"
            placeholder="Выберите источник"
            options={[
              {
                label: "Instagram",
                value: "instagram",
                title: "Instagram",
              },
              {
                label: "TikTok",
                value: "tiktok",
                title: "TikTok",
              },
              {
                label: "Веб-сайт",
                value: "website",
                title: "Веб-сайт",
              },
            ]}
            onChange={(value) => {
              const _options = [...options];
              _options[option].source = value;
              setOptions(_options);
            }}
            value={item?.source}
            size="large"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Выделенный блок 1
          </span>
          <Input
            className="mb-2"
            value={item?.highlight1_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].highlight1_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. Подписчики)"
          />
          <Input
            value={item?.highlight1_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].highlight1_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 100K)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Выделенный блок 2
          </span>
          <Input
            className="mb-2"
            value={item?.highlight2_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].highlight2_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. Подписчики)"
          />
          <Input
            value={item?.highlight2_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].highlight2_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 100K)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Выделенный блок 3
          </span>
          <Input
            className="mb-2"
            value={item?.highlight3_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].highlight3_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. Подписчики)"
          />
          <Input
            value={item?.highlight3_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].highlight3_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 100K)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Разделение по аудитории
          </span>
          <Input
            className="mb-2"
            value={item?.audience_women}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_women = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение «Женщины» (напр. 50%)"
          />
          <Input
            value={item?.audience_men}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_men = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение «Мужчины» (напр. 50%)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Мини-блок «Возраст аудитории» 1
          </span>
          <Input
            className="mb-2"
            value={item?.audience_age1_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age1_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. 18-24 года)"
          />
          <Input
            value={item?.audience_age1_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age1_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 20%)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Мини-блок «Возраст аудитории» 2
          </span>
          <Input
            className="mb-2"
            value={item?.audience_age2_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age2_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. 18-24 года)"
          />
          <Input
            value={item?.audience_age2_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age2_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 20%)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Мини-блок «Возраст аудитории» 3
          </span>
          <Input
            className="mb-2"
            value={item?.audience_age3_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age3_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. 18-24 года)"
          />
          <Input
            value={item?.audience_age3_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age3_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 20%)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Мини-блок «Возраст аудитории» 4
          </span>
          <Input
            className="mb-2"
            value={item?.audience_age4_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age4_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Название блока (напр. 18-24 года)"
          />
          <Input
            value={item?.audience_age4_value}
            onChange={(e) => {
              const _options = [...options];
              _options[option].audience_age4_value = e.target.value;
              setOptions(_options);
            }}
            placeholder="Значение блока (напр. 20%)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Ссылка при нажатии
          </span>
          <Input
            value={item?.href}
            onChange={(e) => {
              const _options = [...options];
              _options[option].href = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите ссылку"
            prefix={<LinkOutlined />}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Открыть ссылку в новой вкладке
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _options = [...options];
              _options[option].href_target_blank = value;
              setOptions(_options);
            }}
            defaultValue={false}
            value={item?.href_target_blank}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Атрибут ссылки rel
          </span>
          <Input
            value={item?.href_rel}
            onChange={(e) => {
              const _options = [...options];
              _options[option].href_rel = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите значение (напр. nofollow)"
            prefix={<LinkOutlined />}
          />
        </div>
        <div className="col-span-12">
          <Button
            className="mt-5"
            type="primary"
            block
            onClick={() => setOption(null)}
          >
            Готово
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>

      {options?.length > 0 && (
        <div className="p-3 w-full bg-neutral-50 rounded-md mb-5 grid grid-cols-12 gap-y-3">
          {options?.map((o, i) => (
            <div
              key={i}
              className="col-span-12 flex justify-between items-center"
            >
              <div className="flex justify-start items-center gap-2">
                {o?.source === "instagram" && (
                  <Fragment>
                    <InstagramOutlined /> <span>Instagram</span>
                  </Fragment>
                )}
                {o?.source === "tiktok" && (
                  <Fragment>
                    <TikTokOutlined /> <span>TikTok</span>
                  </Fragment>
                )}
                {o?.source === "website" && (
                  <Fragment>
                    <GlobalOutlined /> <span>Веб-сайт</span>
                  </Fragment>
                )}
              </div>
              <Button.Group>
                <Button
                  disabled={i === 0}
                  onClick={() => onOptionPositionChange(i, -1)}
                  size="small"
                  type="text"
                  icon={<UpOutlined />}
                />
                <Button
                  onClick={() => onOptionPositionChange(i, 1)}
                  disabled={i === options.length - 1}
                  size="small"
                  type="text"
                  icon={<DownOutlined />}
                />
                <Button
                  size="small"
                  type="text"
                  onClick={() => setOption(i)}
                  icon={<EditOutlined />}
                />
                <Button
                  size="small"
                  type="text"
                  danger
                  onClick={() => onOptionDelete(i)}
                  icon={<DeleteOutlined />}
                />
              </Button.Group>
            </div>
          ))}
        </div>
      )}

      {options?.length === 0 && (
        <Empty
          className="my-10 max-w-xs mx-auto"
          description="Источников пока нет. Нажмите «Добавить источник», чтобы добавить."
        />
      )}

      <Button onClick={onOptionAdd} block type="dashed">
        Добавить источник
      </Button>

      <Drawer
        onClose={() => setOption(null)}
        title="Редактировать источник"
        open={option !== null}
      >
        {getDrawerContent()}
      </Drawer>
    </div>
  );
}
