import { Input, Button, Drawer, Select, Empty } from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  UpOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  InstagramOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

export default function Widget({ input, onChange, key, data }) {
  const [options, setOptions] = useState(data?.data?.[input.key] || []);
  const [option, setOption] = useState(null);

  useEffect(() => {
    onChange(input.key, options);
  }, [options]);

  const onOptionAdd = () => {
    setOptions([...options, { source: "website" }]);
    setOption(options.length);
  };

  const onOptionPositionChange = (index, position) => {
    const _options = [...options];
    const item = _options[index];
    _options.splice(index, 1);
    _options.splice(index + position, 0, item);
    setOptions(_options);
  };

  const onOptionDelete = (index) => {
    const _options = [...options];
    _options.splice(index, 1);
    setOptions(_options);
  };

  const getDrawerContent = () => {
    if (option === null) {
      return null;
    }

    const item = options[option];

    return (
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Размещение
          </span>
          <Select
            className="w-full"
            placeholder="Выберите размещение"
            options={[
              {
                label: "Веб-сайт",
                title: "Веб-сайт",
                value: "website",
              },
              {
                label: "Соц.сети",
                title: "Соц.сети",
                value: "social",
              },
            ]}
            onChange={(value) => {
              const _options = [...options];
              _options[option].source = value;
              setOptions(_options);
            }}
            value={item?.source}
            size="large"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Основной заголовок/подзаголовок
          </span>
          <Input
            className="mb-2"
            value={item?.title}
            onChange={(e) => {
              const _options = [...options];
              _options[option].title = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите заголовок"
          />
          <Input
            className="mb-2"
            value={item?.subtitle}
            onChange={(e) => {
              const _options = [...options];
              _options[option].subtitle = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите подзаголовок"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Текстовый блок 1
          </span>
          <Input
            className="mb-2"
            value={item?.block1_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].block1_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Заголовок блока"
          />
          <Input.TextArea
            value={item?.block1_text}
            onChange={(e) => {
              const _options = [...options];
              _options[option].block1_text = e.target.value;
              setOptions(_options);
            }}
            placeholder="Текст блока"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Текстовый блок 2
          </span>
          <Input
            className="mb-2"
            value={item?.block2_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].block2_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Заголовок блока"
          />
          <Input.TextArea
            value={item?.block2_text}
            onChange={(e) => {
              const _options = [...options];
              _options[option].block2_text = e.target.value;
              setOptions(_options);
            }}
            placeholder="Текст блока"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Текстовый блок 3
          </span>
          <Input
            className="mb-2"
            value={item?.block3_label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].block3_label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Заголовок блока"
          />
          <Input.TextArea
            value={item?.block3_text}
            onChange={(e) => {
              const _options = [...options];
              _options[option].block3_text = e.target.value;
              setOptions(_options);
            }}
            placeholder="Текст блока"
          />
        </div>
        <div className="col-span-12">
          <Button
            className="mt-5"
            type="primary"
            block
            onClick={() => setOption(null)}
          >
            Готово
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>

      {options?.length > 0 && (
        <div className="p-3 w-full bg-neutral-50 rounded-md mb-5 grid grid-cols-12 gap-y-3">
          {options?.map((o, i) => (
            <div
              key={i}
              className="col-span-12 flex justify-between items-center"
            >
              <div className="flex justify-start items-center gap-2">
                {o?.source === "website" && (
                  <Fragment>
                    <GlobalOutlined /> <span>Веб-сайт</span>
                  </Fragment>
                )}
                {o?.source === "social" && (
                  <Fragment>
                    <InstagramOutlined /> <span>Соц.сети</span>
                  </Fragment>
                )}
              </div>
              <Button.Group>
                <Button
                  disabled={i === 0}
                  onClick={() => onOptionPositionChange(i, -1)}
                  size="small"
                  type="text"
                  icon={<UpOutlined />}
                />
                <Button
                  onClick={() => onOptionPositionChange(i, 1)}
                  disabled={i === options.length - 1}
                  size="small"
                  type="text"
                  icon={<DownOutlined />}
                />
                <Button
                  size="small"
                  type="text"
                  onClick={() => setOption(i)}
                  icon={<EditOutlined />}
                />
                <Button
                  size="small"
                  type="text"
                  danger
                  onClick={() => onOptionDelete(i)}
                  icon={<DeleteOutlined />}
                />
              </Button.Group>
            </div>
          ))}
        </div>
      )}

      {options?.length === 0 && (
        <Empty
          className="my-10 max-w-xs mx-auto"
          description="Мест размещения пока нет. Нажмите «Добавить размещение», чтобы добавить."
        />
      )}

      <Button onClick={onOptionAdd} block type="dashed">
        Добавить размещение
      </Button>

      <Drawer
        onClose={() => setOption(null)}
        title="Редактировать размещение"
        open={option !== null}
      >
        {getDrawerContent()}
      </Drawer>
    </div>
  );
}
